import React, { useState, useEffect } from 'react'
import logo from '../../Images/logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Drawer,Nav  } from 'rsuite';
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba,
  faDownload,
  faGlobe,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSwitcher from '../Activity/TranslationTrigger';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
function Navbar () {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [openWithHeader, setOpenWithHeader] = React.useState(false);
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
 
  // This function put query that helps to
  // change the language

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
        
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    };
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        sessionStorage.setItem('Catogories',JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories);
     
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const FindPackageDetail = id => {
    toggleDropdown();
    navigation('/umrah_packages', { state: id })
  };
  const languages = [
    { value: "en", text: "English" },
    { value: "ar-SA", text: "Arabic" },
];

  return (
    <>
    <Drawer style={{zIndex:'9999' ,width:'100%'}} open={open} onClose={() => setOpen(false)}>
    <Drawer.Header>
          <Drawer.Title>Haramayn Hotels</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div onClick={() => setOpen(false)} className='mobile-nav-new'>
          <NavLink to='/'>
            <h4>Home</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
          <NavLink to='/about-us'>
            <h4>About us</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
          <NavLink to='/contact-us'>
            <h4>Contact us</h4>
            </NavLink>
          </div>
        </Drawer.Body>
      </Drawer>
      <header style={{ boxShadow: '0 2px 1px 0 rgba(12,14,28,.12)'}}>
        <div className='navbar-top'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 navbar-img col-sm-12'>
              <div className=' d-flex justify-content-between align-items-center'>
                <img  src={logo} alt="expedia logo" height={70} />
              <h4 onClick={() => setOpen(true)} className='mobile-bar'><FontAwesomeIcon icon={faBars}/></h4>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12' style={{margin:'auto'}}>
              <div className='navbar-top-right'>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/'>
                  <button className='btn btn-success d-flex non'>
               <h5 className='non' style={{color:'black', fontSize:'14px'}}>Home</h5> </button></NavLink> </div>
                <div className='d-flex top-button-effect non'>  
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> Activities</h5> </button>
                </div>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/about-us'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> About us</h5> </button>
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/contact-us'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> Contact us</h5> </button>
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                <button
                    onClick={toggleModal}
                    style={{color:'#fff',background:'#9c2243',borderRadius:'5px'}}
                    className='btn btn-primary currency-btn'
                  >
                    {selectedCurrency}
                  </button>
                </div>
                {/* <div className='d-flex top-button-effect'>
                  <button className='btn btn-success '> 
                    <FontAwesomeIcon className='navbar-sign-in' icon={faBell} />
                  </button>
                </div> */}
                {/* <div className='d-flex top-button-effect'>
                  <button className='btn btn-success '>
                    <h5 style={{color:'black', fontSize:'14px'}}>Sign in</h5>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <nav
          id='navbar'
          className={
            'navbar-expand-lg pb-2  navbar-dark   ' +
            (isMobileNavOpen ? 'navbar-mobile' : 'navbar')
          }
        >
          <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
          >
            {isMobileNavOpen ? (
              <span
                style={{ color: 'white' }}
                onClick={toggleMobileNav}
                className='mobile-nav-toggle '
              >
                {' '}
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={toggleMobileNav}
                className='mobile-nav-toggle navbar-toggler-icon'
              ></span>
            )}
          </button>
          <ul className='navbar-nav navbar-link  ml-auto'>
            <li className='nav-item nav-link scrollto  active'>
              <NavLink className='nav-link' to='/'>
              {t('Home')}
              </NavLink>
            </li>
            <li className='nav-item ps-3 dropdown nav-link scrollto'>
              <h5 onClick={toggleDropdown} className='nav-link'>
                 {t('Packages')}{' '}
                <FontAwesomeIcon onClick={toggleDropdown} icon={faAngleDown} />
              </h5>
              {isDropdownOpen2 && (
                <ul>
                  {dropdownCat.map((item, index) => (
                    <li key={index} className='mb-3'>
                      <h6
                        onClick={() => FindPackageDetail(item.id)}
                        className='dropdown-nav-link '
                      >
                         {t(`${item.title}`)}
                      </h6>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/hajj'>
              <FontAwesomeIcon  className='fa-bold fw-bold' icon={faKaaba} />{' '}
              {t('Hajj 2024')} 
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/about-us'>
              {t('About Us')} 
              </NavLink>
              <span class=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
            <li className='nav-item nav-link scrollto'>
              <NavLink className='nav-link' to='/contact-us'>
              {t('Contact Us')}
              </NavLink>
              <span className=' submenu-toggle'>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </li>
          </ul>
        </nav> */}
      </header>

      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar
