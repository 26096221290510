import React, { useState, useEffect } from 'react'
import img1 from '../../Images/Home/imagination1.png'
import img2 from '../../Images/Home/imagination2.png'
import img3 from '../../Images/Home/imagination3.png'
import m_app from '../../Images/Pages/mobile-app.png'
import bigimage from '../../Images/Home/big-img.png'
import before1 from '../../Images/Home/beforeGo1.jpg'
import before2 from '../../Images/Home/beforeGo2.jpg'
import before3 from '../../Images/Home/beforeGo3.jpg'
import before4 from '../../Images/Home/beforeGo4.jpg'
import { NavLink, useNavigate } from 'react-router-dom'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useDispatch,useSelector } from 'react-redux'
import OwlCarousel from 'react-owl-carousel'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
import { ApiEndPoint,Hotelapitoken,CurrencyConverter } from '../../Components/GlobalData/GlobalData'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import emailjs from 'emailjs-com';
import Loader from '../../Components/Loading/Loader';
import { fetchHotelsSearh,fetchHotels } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt,faStar } from '@fortawesome/free-solid-svg-icons'
function IndexComponents () {
  const { t, i18n } = useTranslation()
  const language = i18n.language;
  var token = Hotelapitoken();
  var apiendpoint=ApiEndPoint();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false)
  const [isloadingMakkah, setLoadingMakkah] = useState(true)
  const [isloadingMadinah, setLoadingMadinah] = useState(true)
  const [makkahHotels, setMakkahHotels] = useState([])
  const [MadinahHotels, setMadinahHotels] = useState([])
  const [packagescard, setPackagescard] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [GetCurrency, setGetCurrency] = useState(true)
  const [makkahDetail, setMakkahDetail] = useState({});
  const [madinahDetail, setMadinahDetail] = useState({});
  const [itemsToShow, setItemsToShow] = useState(4)
  const [baseCName, setBaseCName] = useState('GBP')
  const [showPrice, setShowPrice] = useState(true);
  const [baseCurrency, setBaseCurrency] = useState([])
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )

    setShowTours(
      filter.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    )
    // Set the selected category as the active one.
  }

  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 530) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(()=>{
    getUserLocation();
  },[]);
  function getUserLocation () {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        SearchMakkahhotels(data.country.name);
        SearchMadinahhotels(data.country.name);
        localStorage.setItem('usercountry',data.country.name);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(apiendpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      setMakkahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };
  
  const fetchHotelDetails1 = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(apiendpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      setMadinahDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };
  const SearchMakkahhotels = async (userCountry) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":'Makkah',
      "country":"Saudi Arabia",
      "lat":  21.4240968,
      "long": 39.81733639999999,
      "pin": "SA",
      "cityd":'Makkah',
      "country_code":"SA",
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userCountry,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new_Live',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      sessionStorage.removeItem('FlightCheckOut');
      response.data.hotels_list.slice(0, 4).forEach(hotel => {
        if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        }
    });
    if(response.data.hotels_list.length !==0){
      setGetCurrency(false);
      AllCurrency(response.data.hotels_list[0]?.hotel_curreny);
    }
      setMakkahHotels(response.data);
      setLoadingMakkah(false);
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };
  const SearchMadinahhotels = async (userCountry) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":'Madinah',
      "country":"Saudi Arabia",
      "lat":24.4672132,
      "long":39.6024496,
      "pin": "SA",
      "cityd":'Madinah',
      "country_code":"SA",
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userCountry,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new_Live',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      sessionStorage.removeItem('FlightCheckOut');
      response.data.hotels_list.slice(0, 4).forEach(hotel => {
        if (!madinahDetail[hotel.hotel_id]) {
          fetchHotelDetails1(hotel.hotel_id, hotel.hotel_provider)
        }
    });
    if(GetCurrency){

      AllCurrency(response.data.hotels_list[0]?.hotel_curreny);
    }
      setMadinahHotels(response.data);
      setLoadingMadinah(false);
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };
  const RoomDetailPage = async (id, index,city) => {
    localStorage.setItem('HotelCurrency', null)
    var hotelRoomdetail=[];
    if(city==='makkah'){
      hotelRoomdetail = makkahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
      dispatch(fetchHotels(makkahHotels))
    }else if(city==='madinah'){
      hotelRoomdetail = MadinahHotels.hotels_list.filter(
        item => item.hotel_id == id
      )
      dispatch(fetchHotels(MadinahHotels))
    }
    
    setLoading(true)
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        apiendpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigation(`/hotel_detail/${id}`, { state: { index } })
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false)
      console.error('Error:', error)
    }
  };
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price))
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price))
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1))
    }
    return baseprice.toFixed(0)
  };

  const AllCurrency = curency => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + curency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data
        setBaseCurrency(response.data.conversion_rates)
        localStorage.setItem('HotelCurrency', JSON.stringify(response.data))
      })
      .catch(error => {
        // Handle errors here
        setShowPrice(false)
        console.error(error)
      })
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    return finalpricemarkup
  };
 
  return (
    <>
    {isloading && (<Loader/>)}
      <div className='container-fluid mt-4'>
        {/* <div class='uitk-spacing bex-homepage-module SimpleContainer'>
          <div data-testid='one-key-banner-1' id='one-key-banner-1'>
            <div>
              <div
                class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme'
                data-stid='one-key-message-card'
              >
                <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                  <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                    <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                      <img
                        class='uitk-mark uitk-mark-landscape-oriented'
                        alt=''
                        src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                        id='onekey__standard__always_light'
                      />
                    </div>
                  </div>
                  <div class='uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                    <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                      <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                        <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                          <h2 class='uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'>
                            Save 10% or more on over 100,000 hotels with Member
                            Prices. Also, members save up to 30% when you add a
                            hotel to a flight
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div class='uitk-spacing whiteBackground bex-homepage-module SimpleContainer'>
          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-four uitk-spacing-padding-inline-three'></div>
          </div>
          <div className='row align-items-center mt-4 mb-4'>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='heading_left_area'>
                <h3>Go beyond your imagination</h3>
                <h5>Discover your ideal experience with us</h5>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img1} alt='img' />
                </a>
                <h3>
                  <p>7% Discount for all Transfers</p>
                </h3>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img2} alt='img' />
                </a>
                <h3>
                  <p>Special Managers Hotel Makkah</p>
                </h3>
              </div>
            </div>
            <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
              <div class='imagination_boxed'>
                <a href='#'>
                  <img src={img3} alt='img' />
                </a>
                <h3>
                  <p>Special Managers Hotel Medina</p>
                </h3>
              </div>
            </div>
          </div>

          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three'></div>
          </div>
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div id='retail-recommendation-module-1'>
                  <div>
                    <h2
                      class='uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-margin-blockstart-unset'
                      aria-hidden='false'
                    >
                      Explore Saudi Arabia
                    </h2>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-three'>
                      <div class='row'>
                        <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div class='destinations_content_box img_animation'>
                            <img
                              src={bigimage}
                              alt='img'
                            />
                            <div class='destinations_content_inner'>
                              <h2>Up to</h2>
                              <div class='destinations_big_offer'>
                                <h1>25</h1>
                                <h6>
                                  <span>%</span> <span>Off</span>
                                </h6>
                              </div>
                              <h2>Hotel Bookings</h2>
                              <button className='btn btn-primary search-btn1'>
                                Book now
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div class='row'>
                            <div class='col-lg-6 col-md-6 col-sm-12 col-12'>
                              <NavLink to='/about_saudi' target='blank'>
                              <div class='destinations_content_box img_animation'>
                                <div class="image-wrapper">
                                    <img style={{height:'20em',objectFit:'cover'}} src={before3} alt='img' />
                                    <div class="overlay"></div> 
                                  </div>
                                <div class='text-center destinations_content_inner'>
                                  <h4>About Saudi</h4>
                                </div>
                              </div>
                              </NavLink>
                              <NavLink to='/travel_tips' target='blank'>
                              <div class='destinations_content_box img_animation'>
                                 
                               <div class="image-wrapper">
                                    <img style={{height:'20em',objectFit:'cover'}} src={before1} alt='img' />
                                    <div class="overlay"></div> 
                                  </div>
                                <div class='text-center destinations_content_inner'>
                                  <h4>Safety Travel Tips</h4>
                                </div>
                              </div>
                              </NavLink>
                            </div>
                            <div class='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <NavLink to='/useful_contacts' target='blank'>
                              <div class='destinations_content_box img_animation'>
                                  
                               <div class="image-wrapper">
                                    <img style={{height:'20em',objectFit:'cover'}} src={before2} alt='img' />
                                    <div class="overlay"></div> 
                                  </div>
                                <div class='text-center destinations_content_inner'>
                                  <h4>Useful Contacts</h4>
                                </div>
                              </div>
                              </NavLink>
                              <div class='destinations_content_box img_animation'>
                                  <div class="image-wrapper">
                                    <img style={{height:'20em',objectFit:'cover'}} src={before4} alt='img' />
                                    <div class="overlay"></div> 
                                  </div>
                                  <div class='text-center destinations_content_inner'>
                                    <h4>Travel Regulations</h4>
                                  </div>
                                </div>

                              {/* <div class='destinations_content_box'>
                                <button className='btn btn-primary search-btn1'>
                                  View All
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                         Hotels in Makkah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div >
                                {isloadingMakkah ? (
                                  <div className='row'>
                                  <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                               
                                  </div>
                                ):(
                                  <div className='row'>
                                    {makkahHotels.hotels_list.slice(0, 4).map((item,index)=>(
                                      <div key={index}  class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div style={{cursor:'pointer'}} onClick={() => RoomDetailPage(item.hotel_id, index,'makkah')} class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <a>
                                      {makkahDetail[item.hotel_id] &&
                                            makkahDetail[item.hotel_id].details_data &&
                                            makkahDetail[item.hotel_id].details_data.image ? (
                                            
                                                <img
                                                  style={{ height: '172px' }}
                                                  src={
                                                    makkahDetail[item.hotel_id].details_data.image
                                                  }
                                                  alt=''
                                                />
                                            ) : (
                                              <img
                                          src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                          style={{ height: '172px' }}
                                          alt='img'
                                        />
                                            )}
                                        
                                      </a>
                                      <p>
                                        <i class='fas fa-map-marker-alt'>
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                          />
                                        </i>
                                        Makkah
                                      </p>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <h5>{item.hotel_name}</h5>
                                      <p className='card-star'>
                                              {item.stars_rating === '' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                Array(item.stars_rating)
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))
                                              )}
                                            </p>
                                      <h5 className='mb-2'>
                                {showPrice ? (
                              <super> Price starts from {''}
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )
                                )}
                              </super>
                            
                           
                          ) : (
                          
                              <super> Price starts from {''}
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ).toFixed(2)}
                              </super>
                              
                          )}
                               
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="uitk-carousel-controls">
                                        <button data-stid="carousel-nav-prev" aria-label="Previous" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-prev uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Previous" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="prev-button-title">Previous</title>
                                                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                            </svg>
                                        </button>
                                        <button data-stid="carousel-nav-next" aria-label="Next" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-next uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Next" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="next-button-title">Next</title>
                                                <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                            </svg>
                                        </button>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
            <div class='lazyload-wrapper LazyLoadRegion'>
              <div>
                <div>
                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column'>
                    <div class='uitk-spacing uitk-spacing-margin-blockstart-two'>
                      <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                        <h3 class='uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item'>
                         Hotels in Madinah
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class='uitk-spacing uitk-spacing-margin-blockstart-six'>
                    <div
                      class='uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg'
                      data-stid='carousel-wrapper'
                    >
                      <div class='row'>
                        <div class='col-lg-12'>
                          <div class='tab-content' id='nav-tabContent'>
                            <div
                              class='tab-pane fade show active'
                              id='nav-hotels'
                              role='tabpanel'
                              aria-labelledby='nav-hotels-tab'
                            >
                              <div >
                                {isloadingMadinah ? (
                                  <div className='row'>
                                  <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <div style={{background:'#c88698',height:'12em'}}>

                                      </div>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                               
                                  </div>
                                ):(
                                  <div className='row'>
                                    {MadinahHotels.hotels_list.slice(0, 4).map((item,index)=>(
                                      <div key={index} class='col-lg-3 col-md-6 col-sm-6 col-12'>
                                  <div style={{cursor:'pointer'}} onClick={() => RoomDetailPage(item.hotel_id, index,'madinah')} class='theme_common_box_two img_hover'>
                                    <div class='theme_two_box_img'>
                                      <a >
                                      {madinahDetail[item.hotel_id] &&
                                            madinahDetail[item.hotel_id].details_data &&
                                            madinahDetail[item.hotel_id].details_data.image ? (
                                            
                                                <img
                                                  style={{ height: '172px' }}
                                                  src={
                                                    madinahDetail[item.hotel_id].details_data.image
                                                  }
                                                  alt=''
                                                />
                                            ) : (
                                              <img
                                          src='https://haramaynhotels.com/public/uploads/package_imgs/1698138818.jpg'
                                          style={{ height: '172px' }}
                                          alt='img'
                                        />
                                            )}
                                      </a>
                                      <p>
                                        <i class='fas fa-map-marker-alt'>
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                          />
                                        </i>
                                        Madinah
                                      </p>
                                    </div>
                                    <div class='theme_two_box_content'>
                                      <h5>{item.hotel_name}</h5>
                                      <p className='card-star'>
                                              {item.stars_rating === '' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                Array(item.stars_rating)
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))
                                              )}
                                            </p>
                                      <h5 className='mb-2'>
                                      {showPrice ? (
                              <super> Price starts from {''}
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )
                                )}
                              </super>
                            
                           
                          ) : (
                          
                              <super> Price starts from {''}
                                {item?.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ).toFixed(2)}
                              </super>
                              
                          )}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="uitk-carousel-controls">
                                        <button data-stid="carousel-nav-prev" aria-label="Previous" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-prev uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Previous" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="prev-button-title">Previous</title>
                                                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                            </svg>
                                        </button>
                                        <button data-stid="carousel-nav-next" aria-label="Next" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-next uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Next" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="next-button-title">Next</title>
                                                <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                            </svg>
                                        </button>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SimpleContainer'>
            <div class='uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three'>
              <div class='row'>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div class='about_two_left'>
                    <div class='about_two_left_top'>
                      <h5>Download Our App </h5>
                      <h2>Discover new horizons with Haramayn Hotels</h2>
                      <p>
                        The one-stop destination for all your travel and
                        accommodation needs! We are excited to announce that we
                        have launched our brand-new app, designed to provide you
                        with a seamless and convenient booking experience, right
                        at your fingertips.
                      </p>
                    </div>
                    <div class='about_two_left_middel'>
                      <div class='about_two_item'>
                        <div class='about_two_item_icon'>
                          <i class='fas fa-download'></i>
                        </div>
                        <div class='about_two_item_text'>
                          <h3>Download the App</h3>
                          <p class='mb-5'>
                            Visit the App Store or Google Play Store and search
                            for "haramaynhotels" to find our official app. Click
                            "Install" to download it onto your device.
                          </p>
                          <a
                            href='https://play.google.com/store/apps/details?id=com.haramaynhotels.haramaynhotels'
                            target='blank'
                            class='btn btn-success mb-3'
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div class='about_two_left_img px-5'>
                    <img
                      src={m_app}
                      alt='img'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SimpleContainer'>
            <div
              class='uitk-spacing NativeAdContainerRegion uitk-spacing-padding-medium-inline-three uitk-spacing-padding-small-inlinestart-three'
              id='native-ad-NMC1'
            >
              <div class=''>
                <meso-native
                  class='is-visually-hidden'
                  aria-hidden='true'
                  uci='NMC1'
                  nativetype='native-ad'
                  isfluid='true'
                  adslot='NMC1'
                  data-testid='meso-native'
                  adunitpath='/expedia.us_en/home/all'
                  timeout='5000'
                  slot='NMC1'
                ></meso-native>
                <div
                  class='fallback'
                  aria-hidden='false'
                  data-testid='native-ad-container'
                ></div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing bex-homepage-module SimpleContainer'>
            <div data-testid='one-key-banner-1' id='one-key-banner-1'>
              <div>
                <div
                  class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme'
                  data-stid='one-key-message-card'
                >
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                    {/* <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                    <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                      <img
                        class='uitk-mark uitk-mark-landscape-oriented'
                        alt=''
                        src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                        id='onekey__standard__always_light'
                      />
                    </div>
                  </div> */}
                    <div class=' container uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                        <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                          <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                            <h3 className='mt-2 mb-2' style={{ color: '#fff' }}>
                              Special Offers
                            </h3>
                            <h2
                              style={{
                                textAlign: 'justify',
                                lineHeight: '1.5em'
                              }}
                              class='uitk-heading mt-2 uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'
                            >
                              We offer a variety of tours and activities that
                              will show you the best that this country has to
                              offer, from its stunning landscapes and rich
                              history to its luxurious experiences. With just a
                              few clicks, you can easily book your
                              accommodation, transfers, visas, and activities
                              all in one place. Our user-friendly B2C platform
                              makes booking your dream trip easier than ever, so
                              you can focus on enjoying your time in Saudi
                              Arabia.
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class='uitk-spacing SpacingContainer inlineSpacingContainerUnset uitk-spacing-padding-block-six uitk-spacing-padding-inline-unset'>
            <div class='uitk-spacing SimpleContainer'>
              <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid GridContainer'>
                <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                  <div class='uitk-spacing SimpleContainer'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                      <div
                        class='uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset'
                        id='editorial-2'
                        data-fm='editorial-2'
                      >
                        <div>
                          <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid'>
                              <div
                                data-testid='image-media-content'
                                class='uitk-layout-grid-item'
                              >
                                <figure class='uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio'>
                                  <div class='uitk-image-placeholder'>
                                    <img
                                      alt=''
                                      class='uitk-image-media'
                                      src='https://a.travel-assets.com/travel-assets-manager/cread-2739-image-resize-prop/Madrid_0390_384x256.jpg'
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                data-testid='editorial-content'
                                class='editorialContent '
                              >
                                <div
                                  class='uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three'
                                  data-fm-content='63937265'
                                  id='63937265'
                                >
                                  <div class='uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one'>
                                    EARN UP TO 10X ONEKEYCASH
                                  </div>
                                  <h2 class='uitk-heading uitk-heading-6'>
                                    Earn more OneKeyCash on your flight when you
                                    book a package
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <a
                              href='https://www.expedia.com/Vacation-Packages?rfrr=Loyalty.HP.TileStack3x1'
                              class='uitk-card-link'
                            >
                              <span class='is-visually-hidden'>
                                EARN UP TO 10X ONEKEYCASH
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                  <div class='uitk-spacing SimpleContainer'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                      <div
                        class='uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset'
                        id='editorial-3'
                        data-fm='editorial-3'
                      >
                        <div>
                          <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid'>
                              <div
                                data-testid='image-media-content'
                                class='uitk-layout-grid-item'
                              >
                                <figure class='uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio'>
                                  <div class='uitk-image-placeholder'>
                                    <img
                                      alt=''
                                      class='uitk-image-media'
                                      src='https://a.travel-assets.com/travel-assets-manager/cread-2735/BEX-BucketList2024-HP-Card-DM-384x256.jpg'
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                data-testid='editorial-content'
                                class='editorialContent '
                              >
                                <div
                                  class='uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three'
                                  data-fm-content='64077986'
                                  id='64077986'
                                >
                                  <div class='uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one'>
                                    2024 BEST BUCKET LIST TRIPS
                                  </div>
                                  <h2 class='uitk-heading uitk-heading-6'>
                                    See our curated list
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <a
                              href='https://www.expedia.com/lp/b/dream-destinations-2024?rfrr=BucketList.HP.TileStack3x2'
                              class='uitk-card-link'
                            >
                              <span class='is-visually-hidden'>
                                2024 BEST BUCKET LIST TRIPS
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                  <div class='uitk-spacing SimpleContainer'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                      <div
                        class='uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset'
                        id='editorial-4'
                        data-fm='editorial-4'
                      >
                        <div>
                          <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid'>
                              <div
                                data-testid='image-media-content'
                                class='uitk-layout-grid-item'
                              >
                                <figure class='uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio'>
                                  <div class='uitk-image-placeholder'>
                                    <img
                                      alt=''
                                      class='uitk-image-media'
                                      src='https://a.travel-assets.com/travel-assets-manager/cread-2595/BEX-TripPlanner-HP-Card-DM-384x256.jpg'
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                data-testid='editorial-content'
                                class='editorialContent '
                              >
                                <div
                                  class='uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three'
                                  data-fm-content='64077991'
                                  id='64077991'
                                >
                                  <div class='uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one'>
                                    INTRODUCING TRIP PLANNER
                                  </div>
                                  <h2 class='uitk-heading uitk-heading-6'>
                                    Save, collaborate, and book with Trip
                                    Planner
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <a
                              href='https://www.expedia.com/why/trip-planning?rfrr=TripPlanner.HP.TileStack3x3'
                              class='uitk-card-link'
                            >
                              <span class='is-visually-hidden'>
                                book for spring
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SpacingContainer inlineSpacingContainerUnset uitk-spacing-padding-block-six uitk-spacing-padding-inline-unset'>
            <div class='uitk-spacing SimpleContainer'>
              <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid GridContainer uitk-two-element'>
                <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                  <div class='uitk-spacing SimpleContainer'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                      <div
                        class='uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset'
                        id='editorial-5'
                        data-fm='editorial-5'
                      >
                        <div>
                          <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid'>
                              <div
                                data-testid='image-media-content'
                                class='uitk-layout-grid-item'
                              >
                                <figure class='uitk-image uitk-card-roundcorner-all uitk-image-ratio-16-9 uitk-image-ratio'>
                                  <div class='uitk-image-placeholder'>
                                    <img
                                      alt=''
                                      class='uitk-image-media'
                                      src='https://a.travel-assets.com/travel-assets-manager/cread-3037/BEX-TopSpring-US-CA-AU-NZ-HP-Card-DM-457x257.jpg'
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                data-testid='editorial-content'
                                class='editorialContent '
                              >
                                <div
                                  class='uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three'
                                  data-fm-content='64077897'
                                  id='64077897'
                                >
                                  <div class='uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one'>
                                    STANDOUT SPRING ESCAPES
                                  </div>
                                  <h2 class='uitk-heading uitk-heading-6'>
                                    Get planning
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <a
                              href='https://www.expedia.com/magazine/top-spring-destinations/?rfrr=TopSpringDestinations.HP.TileStack2x1'
                              class='uitk-card-link'
                            >
                              <span class='is-visually-hidden'>
                                STANDOUT SPRING ESCAPES
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                  <div class='uitk-spacing SimpleContainer'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'>
                      <div
                        class='uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset'
                        id='editorial-6'
                        data-fm='editorial-6'
                      >
                        <div>
                          <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid'>
                              <div
                                data-testid='image-media-content'
                                class='uitk-layout-grid-item'
                              >
                                <figure class='uitk-image uitk-card-roundcorner-all uitk-image-ratio-16-9 uitk-image-ratio'>
                                  <div class='uitk-image-placeholder'>
                                    <img
                                      alt=''
                                      class='uitk-image-media'
                                      src='https://a.travel-assets.com/travel-assets-manager/cread-2923/BEX_DB_GreatValueFlights-HP-Card-DM-457x257.jpg'
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                data-testid='editorial-content'
                                class='editorialContent '
                              >
                                <div
                                  class='uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three'
                                  data-fm-content='63930692'
                                  id='63930692'
                                >
                                  <div class='uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one'>
                                    FLIGHT DEALS FOR 2024
                                  </div>
                                  <h2 class='uitk-heading uitk-heading-6'>
                                    Fly somewhere warm, sunny, and bright
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <a
                              href='https://www.expedia.com/lp/b/greatvalueflights?rfrr=GreatValueFlights.HP.TileStack2x2'
                              class='uitk-card-link'
                            >
                              <span class='is-visually-hidden'>
                                FLIGHT DEALS FOR 2024
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='uitk-spacing SimpleContainer'>
            <div id='partner-gallery-1' data-testid='partner-gallery'>
              <div data-eg-sc='true' data-meso-viewed='true'>
                <div class='LazyLoad is-visible'>
                  <hr class='uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr' />
                  <div class='uitk-layout-grid uitk-layout-grid-has-areas uitk-layout-grid-has-areas-by-medium uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-three uitk-style'>
                    <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end'>
                      <a
                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                        target='_blank'
                        href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                        rel='noopener noreferrer'
                      >
                        <span class='is-visually-hidden'>
                          Open in same window
                        </span>
                        <h2 class='uitk-heading uitk-heading-5'>
                          Find your slice of paradise in Punta Cana
                        </h2>
                      </a>
                      <a
                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                        target='_blank'
                        href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                        rel='noopener noreferrer'
                      >
                        <span class='is-visually-hidden'>
                          Open in same window
                        </span>
                        <p class='uitk-paragraph uitk-paragraph-2'>
                          Create unforgettable memories with an idyllic escape -
                          these hotels are awaiting your arrival.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid'>
                    <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme'>
                      <figure class='uitk-image uitk-image-ratio-21-9 uitk-image-ratio'>
                        <div class='uitk-image-placeholder'>
                          <img
                            alt='Ad'
                            class='uitk-image-media'
                            src='https://tpc.googlesyndication.com/simgad/828231536056687653?'
                          />
                        </div>
                        <div class='uitk-scrim fade-bottom'>
                          <div class='uitk-text in-bottom-scrim'>
                            Riu Hotels and Resorts
                          </div>
                        </div>
                        <a
                          class='uitk-image-link'
                          target='_blank'
                          href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                          rel='noopener noreferrer'
                        >
                          <span class='is-visually-hidden'>
                            Open in same window
                          </span>
                          <span class='is-visually-hidden'></span>
                        </a>
                      </figure>
                      <a
                        class='uitk-card-link'
                        target='_blank'
                        href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                        rel='noopener noreferrer'
                      >
                        <span class='is-visually-hidden'>
                          Open in same window
                        </span>
                        <span class='is-visually-hidden'></span>
                      </a>
                    </div>
                    <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme'>
                      <figure class='uitk-image uitk-image-ratio-21-9 uitk-image-ratio'>
                        <div class='uitk-image-placeholder'>
                          <img
                            alt='Ad'
                            class='uitk-image-media'
                            src='https://tpc.googlesyndication.com/simgad/14321566993403784233?'
                          />
                        </div>
                        <div class='uitk-scrim fade-bottom'>
                          <div class='uitk-text in-bottom-scrim'>
                            Secrets Resorts and Spas
                          </div>
                        </div>
                        <a
                          class='uitk-image-link'
                          target='_blank'
                          href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                          rel='noopener noreferrer'
                        >
                          <span class='is-visually-hidden'>
                            Open in same window
                          </span>
                          <span class='is-visually-hidden'></span>
                        </a>
                      </figure>
                      <a
                        class='uitk-card-link'
                        target='_blank'
                        href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                        rel='noopener noreferrer'
                      >
                        <span class='is-visually-hidden'>
                          Open in same window
                        </span>
                        <span class='is-visually-hidden'></span>
                      </a>
                    </div>
                    <div class='uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme'>
                      <figure class='uitk-image uitk-image-ratio-21-9 uitk-image-ratio'>
                        <div class='uitk-image-placeholder'>
                          <img
                            alt='Ad'
                            class='uitk-image-media'
                            src='https://tpc.googlesyndication.com/simgad/15108923715973843726?'
                          />
                        </div>
                        <div class='uitk-scrim fade-bottom'>
                          <div class='uitk-text in-bottom-scrim'>
                            More hotels
                          </div>
                        </div>
                        <a
                          class='uitk-image-link'
                          target='_blank'
                          href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                          rel='noopener noreferrer'
                        >
                          <span class='is-visually-hidden'>
                            Open in same window
                          </span>
                          <span class='is-visually-hidden'></span>
                        </a>
                      </figure>
                      <a
                        class='uitk-card-link'
                        target='_blank'
                        href='https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home'
                        rel='noopener noreferrer'
                      >
                        <span class='is-visually-hidden'>
                          Open in same window
                        </span>
                        <span class='is-visually-hidden'></span>
                      </a>
                    </div>
                  </div>
                  <hr class='uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr' />
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                <div class="TabGroup" data-fm="1948240">
                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                        <div>
                            <div class="uitk-tabs-container">
                                <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-default uitk-tabs-with-border" role="tablist">
                                    <li data-stid="uitk-tab-active" role="presentation" class="uitk-tab active">
                                        <a href="#" class="uitk-tab-anchor uitk-tab-anchor-selected" aria-controls="tab-57xwexkkd-0" aria-selected="true" id="tab-tab-group-1-tab-1" data-section-id="#section-tab-group-1-tab-1" role="tab" data-toggle="tab" draggable="false">
                                            <span class="uitk-tab-text">Explore a world of travel with Expedia</span>
                                        </a>
                                    </li>
                                    <li aria-hidden="true" class="uitk-tab-highlighter"></li>
                                </ul>
                                <div class="uitk-tabs-content">
                                    <div role="tabpanel" class="uitk-tabs-pane active" id="tab-57xwexkkd-0">
                                        <div>
                                            <div class="uitk-spacing Links" id="links-1" data-fm="links-1">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Hotel deals on top domestic destinations</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two">
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Las Vegas hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New York hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Miami hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Los Angeles hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Chicago hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Nashville hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Myrtle Beach hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New Orleans hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">San Francisco hotels </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-2" data-fm="links-2">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Book hotels internationally</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cabo San Lucas hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">London hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Paris hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Barcelona hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cancun hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Dubai hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Tokyo hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">San Juan hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Los Cabos hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Riviera Maya hotels </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-3" data-fm="links-3">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top Domestic Flights</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Hawaii </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Miami </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Maui </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Houston </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Dallas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Denver </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-4" data-fm="links-4">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top International Flights</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Puerto Rico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Tokyo </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Kannur </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Mexico City </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to London </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Manila </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Cabo San Lucas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Bali </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Bora Bora </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-5" data-fm="links-5">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Save today on vacation packages</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Las Vegas vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hawaii vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New York vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Miami vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Disneyland vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Florida vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New Orleans vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Maui vacation packages </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>  
                                            <div class="uitk-spacing Links" id="links-6" data-fm="links-6">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Book international flights &amp; hotels together to save</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cancun vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Punta Cana vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Aruba vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Europe vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Caribbean vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Bahamas vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Italy vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">London vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Turks and Caicos vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Paris vacation packages </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-7" data-fm="links-7">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top places to visit domestically</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Chicago </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Dallas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Atlanta </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Phoenix </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Miami </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Houston </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-8" data-fm="links-8">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Visit places internationally</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit the Bahamas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Vancouver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Mexico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Cabo San Lucas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Punta Cana </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Australia </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit London </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Aruba </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Paris </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-9" data-fm="links-9">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Airport &amp; car rental deals</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Denver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Phoenix </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Chicago </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Fort Lauderdale </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Atlanta </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Houston </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-10" data-fm="links-10">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Find cheap car rentals at international airports</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Dublin </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Vancouver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Toronto </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Toronto </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Dubai </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Paris </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Puerto Rico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Rome </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Calgary </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        <div class="uitk-spacing Links" id="links-11" data-fm="links-11">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top Vacation Rental Destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">United States Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Florida Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals in North Carolina </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals in South Carolina </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Outer Banks Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Myrtle Beach Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Destin Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Panama City Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hilton Head Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Gulf Shores Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Maui Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Pigeon Forge Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Anna Maria Island vacation rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Lake of the Ozarks Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals by the beach </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Luxury vacation rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation rentals near by </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-12" data-fm="links-12">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Popular cruise destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Bahamas </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Mexico </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Hawaii </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Alaska </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Caribbean </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Europe </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Bermuda </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Africa </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Australia &amp; New Zealand </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Canada &amp; New England </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-13" data-fm="links-13">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Cruise departure ports nearby</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Galveston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Miami </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from New York </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Tampa </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Long Beach </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Boston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Charleston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Port Canaveral </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from San Francisco </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Norfolk </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-14" data-fm="links-14">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Other Vacation ideas</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Travel Trends 2024 </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Christmas Vacation Deals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Weekend Getaways </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Plan your Vacation </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Deposit and Payment Plan </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Expedia Group wildlife guidelines </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">All Inclusive Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Ski Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Beach Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Golf Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Kid Friendly Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Luxury Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Romantic Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Adventure Vacations </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-15" data-fm="links-15">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">More destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">All Destinations </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
      </div>
    </>
  )
}

export default IndexComponents
