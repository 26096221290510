
import React,{useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faInfoCircle,
  faPerson,
  faCar,
  faSuitcase,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loader";
import { Popover, Whisper } from 'rsuite';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Axios from "axios";
import moment from "moment";
import { NewTransferSearchToken,ApiEndPoint } from "../GlobalData/GlobalData";
function TransferCard(props){
  const [isLoading, setIsLoading] = useState(false);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const token=NewTransferSearchToken();
  const endpoint=ApiEndPoint();
  const navigate=useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const TransferPerPage =10; // Number of hotels to display per page
  const indexOfLastTransfer = currentPage * TransferPerPage;
  const indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage;
  const currentransfers =props.TransferData.slice(indexOfFirstTransfer, indexOfLastTransfer);
  const pagesCount = Math.ceil(props.TransferData.length / TransferPerPage);
  const validCurrentPage = currentPage < 1 ? 1 : currentPage;
  const maxPagesToShow = 5;

  const startHotelIndex = indexOfFirstTransfer + 1;
  const endHotelIndex = Math.min(indexOfLastTransfer, props.TransferData.length);
  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  }, [ currentPage, pagesCount]); 

  useEffect(() => {
    setCurrentPage(1);
  }, [ props.TransferData]); 

  const renderPaginationItems = () => {
    const items = [];
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href="#">
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };
  const BookTransfer=async(id)=>{
    var transfer=props.TransferData.filter((item)=>item.destination_id===id);
    sessionStorage.setItem('TransferID', id);
    if(transfer[0].booking_From !=="3rd Party API"){
      navigate('/transfer-checkout');
    }else{
      setIsLoading(true);
      var extras_avline=[];
      if(transfer[0]?.extras_Avline){
     extras_avline=JSON.parse(transfer[0]?.extras_Avline);
    }
     
      var data={
       'token': token,
       'extras_Avline':extras_avline.length ===0 ? JSON.stringify([]):JSON.stringify(extras_avline),
       'sessionID':props.sessionID,
	      'bookingid': transfer[0].destination_id,
      }

      try {
         
        const response = await Axios.post(endpoint+'/api/book_Transfer_Api',data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          } ,
          
        });
        setIsLoading(false);
        if(response.data.message==="Success"){
          sessionStorage.setItem('TransferTransectionNumber', response.data.transactionNumber);
          if(response.data?.extras_Data){
            sessionStorage.setItem('Extradata', JSON.stringify(response.data.extras_Data));
          }else{
            sessionStorage.setItem('Extradata', JSON.stringify([]));
          }
          navigate('/transfer-checkout');
        }
      
      } catch (error) {
        setIsLoading(false);
        console.error('Error:', error);
      }


    }
  };
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice =price;
    } else {
      const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
      var baseprice =newprice;
     
    }
    return baseprice
  };


    return(
        <>
        {isLoading && ( <Loading/>)}
        <div>
          <div className='filter-page__content'>
            <div className='fw-bold  m-2'>Showing {startHotelIndex} to {endHotelIndex} of {props.TransferData.length} Transfers</div>                   
            <div className='filter-item-wrapper' id='tours_filter'>
              {currentransfers.map((item, index) =>{ 
                 var SubLocation=[];
                 if(item.more_destination_details !=='' && item.more_destination_details !==null ){
                  SubLocation=JSON.parse(item.more_destination_details);
                 };
                 var extra_Avline=[]
                 if(item?.extras_Avline){
                  extra_Avline = JSON.parse(item.extras_Avline);
                }
                 var PopoverContent=item.more_destination_details !=='' ?(
                  SubLocation.map((loc,index)=>{
                    return(
                    <div key={index} className="col-md-12">
                      <p className="fw-bold text-center">Destination {index+2}</p>
                      <p className="item-address"><strong className="text-dark">Pick-up : </strong>{loc.subLocationPic}</p>
                      <p className="item-address"><strong className="text-dark">Drop-off : </strong>{loc.subLocationdrop}</p>
                    </div>
                    )}) ):null;
              return(
                  <div key={index} className='mb-4'>
                    <div className='row parent_row py-0 mx-2' >
                      <div className='col-md-3 item-from px-0'>
                      <img alt="Room" class="uitk-image-media" src={item.vehicle_image} />
                          
                      </div>
                      <div className='col-md-9'>
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 p-2">
                          <div class="uitk-layout-flex">
                            <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-layout-flex-item-flex-shrink-1">
                              <div class="d-flex justify-content-between uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid flex-container">
                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-layout-grid-item" aria-hidden="false"> {item.vehicle_Name} </h3>
                                <h6 style={{color:'black'}}>Type : {item?.TransferType ?item?.TransferType:item.transfer_type }</h6>
                              </div>
                          <div className='row'>
                            <p className='text-primary fw-bold mb-0'>
                            Pick-up Date : {moment(item.pickup_date).format('DD-MM-YYYY')}{' '}
                            </p>
                            <p className="fw-bold mt-0">Pick-Up Location</p>

                              <div class='item-address'>
                                <i class='awe-icon awe-icon-marker-2'>
                                  <FontAwesomeIcon icon={faGlobe} />
                                </i>{' '}
                                {item.pickup_City}{' '}
                              </div>
                              <p className="fw-bold">Drop-Off Location</p>
                              <div class='item-address'>
                                <i class='awe-icon awe-icon-marker-2'>
                                  <FontAwesomeIcon icon={faGlobe} />
                                </i>{' '}
                                {item.dropof_City}{' '}
                              </div>
                              { extra_Avline.length !==0 && (
                                <div>
                                <p className='text-center header__center fw-bold mt-2'>Extra</p>
                              
                               {extra_Avline.map((item1,index)=>(
                                 <div key={index} className="row">
                                  <div  className=' col-sm-12 col-12 col-md-8 col-lg-8 mt-1'> 
                                         <p class=''>{item1.Extras_Description}</p>
                                        
                                  </div>
                                  <div  className='col-sm-12 col-12 col-md-4 col-lg-4 mt-1'> 
                                  
                                  <p class=''>{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item1.Price))}</p>

                           </div>
                           </div>
                                ))}
                                
                                </div>
                              )}
                          </div>
                             
                            </div>
                            <div class="uitk-layout-flex justify-content-center uitk-layout-flex-align-items-flex-end uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" style={{flexBasis: '117px', textAlign:'end'}}>
                              <div class="uitk-layout-flex-item">
                                <div>
                                  <section class="cars-offer-price right-align">
                                  {item.more_destination_details !=='' && item.more_destination_details !==null && (
                                      <Whisper
                                      placement="top"
                                      trigger="click"
                                      speaker={<Popover >{PopoverContent}</Popover>}
                                    >
                                    <h6 className='text-center m-2 p-view-detail' >
                                      View Detail
                                    </h6>
                                    </Whisper>
                                    )}
                                    <div class="price-qualifier-container" aria-hidden="true">
                                      <span class="per-day-price">{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item.total_fare_markup))}</span>
                                      <div class="per-day-price-qualifier"></div>
                                    </div>
                                    {/* <div class="total-price-container">
                                      <span class="total-price-container" aria-hidden="true">
                                        <span class="total-price">{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item.total_fare_markup))}</span>
                                        <span class="uitk-spacing total-price-qualifier uitk-spacing-padding-inlinestart-one">total</span>
                                      </span>
                                    </div> */}
                                  </section>
                                </div>
                              </div>
                              <a data-stid="default-link" data-testid="default-link" class="uitk-spacing offer-reserve-button uitk-spacing-margin-block-three">
                                <button tabindex="-1" name="textonly" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary" onClick={()=>BookTransfer(item.destination_id)}> Reserve </button>
                              </a>
                              <div class="uitk-layout-flex-item"> <div> </div> </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='row mt-3'>
                              {item.OccupancyFrom && (
                                  <div className=' col-sm-6 col-6 col-md-3 col-lg-3 mt-1' >
                                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                            <i class='fas fa-check'>
                                              <FontAwesomeIcon icon={faPerson} />
                                            </i>
                                          </div>
                                          <div class='single-feature-titles'>
                                            <p class='title fw-bold'>Capacity</p>
                                            <p class='title fw-bold mt-0'>{item.OccupancyFrom} to {item.OccupancyTo}</p>
                                          </div>
                                        </div>
                                  </div>
                              )}
                              {item.SmallBagAllowance && (
                                  <div className=' col-sm-6 col-6 col-md-3 col-lg-3 mt-1'>
                                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                            <i class='fas fa-check'>
                                              <FontAwesomeIcon icon={faSuitcase} />
                                            </i>
                                          </div>
                                          <div class='single-feature-titles'>
                                            <p class='title fw-bold'>Baggages</p>
                                            <p class='title fw-bold mt-0'>{item.SmallBagAllowance} to {item.BigBagAllowance}</p>
                                          </div>
                                        </div>
                                  </div>
                              )}
                              {item.duration && (
                                  <div className=' col-sm-6 col-6 col-md-3 col-lg-3 mt-1'>
                                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                            <i class='fas fa-check'>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                          </div>
                                          <div class='single-feature-titles'>
                                            <p class='title fw-bold'>Duration</p>
                                            <p class='title fw-bold mt-0'>{item.duration} </p>
                                          </div>
                                        </div>
                                  </div>
                              )}
                              {item.VehicleClass && (
                                  <div className=' col-sm-6 col-6 col-md-3 col-lg-3 mt-1'>
                                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                            <i class='fas fa-check'>
                                              <FontAwesomeIcon icon={faCar} />
                                            </i>
                                          </div>
                                          <div class='single-feature-titles'>
                                            <p class='title fw-bold'>Class</p>
                                            <p class='title fw-bold mt-0'>{Object.keys( item.VehicleClass).length === 0 ? item.VehicleMake: item.VehicleClass }</p>
                                          </div>
                                        </div>
                                  </div>
                              )}
                         
                          </div>
                      {/* <div className='col-md-3'>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                          <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                            <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                  <p className="fw-bold">Pick-Up</p>
                                  <div class='item-address'>
                                    <i class='awe-icon awe-icon-marker-2'>
                                      <FontAwesomeIcon icon={faGlobe} />
                                    </i>{' '}
                                    {item.pickup_City}{' '}
                                  </div>
                                  <p className="fw-bold">Drop-Of</p>
                                  <div class='item-address'>
                                    <i class='awe-icon awe-icon-marker-2'>
                                      <FontAwesomeIcon icon={faGlobe} />
                                    </i>{' '}
                                    {item.dropof_City}{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                              <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                        <span class="uitk-badge-base-text" aria-hidden="true">
                                          Date : {item.pickup_date}{' '}
                                        </span>
                                      </span>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                          <span class="is-visually-hidden">  
                                            <p className='card-star'>
                                            {item.starts_rating === 'No_Rating' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                <>
                                                  {item.starts_rating}.0
                                                  {Array.from({
                                                    length: parseInt(item.starts_rating, 10)
                                                  }).map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      {' '}
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))}
                                                </>
                                              )}
                                            </p>
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                          <span class="is-visually-hidden">(555 reviews)</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    
                    </div>
                  </div>
              )})}
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12  col-12'>
                <div className='fw-bold  m-2'>Showing {startHotelIndex} to {endHotelIndex} of {props.TransferData.length} Transfers</div>                   
              </div>
              <div className='col-md-6 col-sm-12 col-12'>
                  <Pagination aria-label="Page navigation example ">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => paginate(validCurrentPage  - 1)} />
                    </PaginationItem>
                    {renderPaginationItems()}
                    <PaginationItem disabled={currentPage === pagesCount}>
                      <PaginationLink next onClick={() => paginate(validCurrentPage  + 1)} />
                    </PaginationItem>
                  </Pagination>            
              </div>
            </div> 
          </div>
        </div>
        </>
    );
}

export default TransferCard;